import '../css/styles.scss'
import Site from '../lib/Site.js'
import Search from '../lib/Search.js'
import Form from '../lib/Form.js'

import '@fontsource/open-sans/latin-300.css'
import '@fontsource/open-sans/latin-300-italic.css'
import '@fontsource/open-sans/latin-400.css'
import '@fontsource/open-sans/latin-400-italic.css'
import '@fontsource/open-sans/latin-500.css'
import '@fontsource/open-sans/latin-500-italic.css'
import '@fontsource/open-sans/latin-600.css'
import '@fontsource/open-sans/latin-600-italic.css'
import '@fontsource/open-sans/latin-700.css'
import '@fontsource/open-sans/latin-700-italic.css'
import '@fontsource/open-sans/latin-800.css'
import '@fontsource/open-sans/latin-800-italic.css'

if (document.body.id !== 'form-builder-form') {
	window._site = new Site()
	window._search = new Search()
	window._form = new Form()
}
