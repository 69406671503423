'use strict'

/**
 * Search class contains features specific to the search feature.
 *
 * @version 0.1.0
 */
export default class Search {

	/**
	 * Class constructor
	 */
	constructor () {
		this.init()
	}

	/**
	 * Init the class by calling applicable init methods
	 *
	 * @return {Object}
	 */
	init () {

		// Set up caches
		this.countCache = {}
		this.isCounting = false

		// Call individual init methods
		this.initDoubleSubmissionPrevention()
		this.initSort()
		this.initCount()
		this.initSearchHistory()
		this.initClearEmptyFields()

		// Dispatch custom event when init is done
		document.dispatchEvent(
			new CustomEvent('search-form-init-done', {
				bubbles: true,
				cancelable: true,
			})
		)

		return this
	}

	/**
	 * Initialize feature that prevents double submissions
	 */
	initDoubleSubmissionPrevention () {
		document.addEventListener('submit', event => {
			const form = event.target
			if (!form.classList.contains('registration-form')) return
			if (form.classList.contains('registration-form--in-progress')) {
				event.preventDefault()
				return
			}
			form.classList.add('registration-form--in-progress')
		})
	}

	/**
	 * Initialize feature that sorts search results when sort options are changed
	 */
	initSort () {
		document.addEventListener('change', event => {

			// Get the select
			const select = event.target
			if (!select.classList.contains('js-sort')) return

			// Get the form
			const form = document.querySelector('.js-search-form')
			if (!form) return

			// Update the sort input
			const sortInput = form.querySelector('[name="sort"]')
			if (!sortInput) return

			sortInput.value = select.value

			// Submit the form
			form.submit()
		})
	}

	/**
	 * Initialize feature that updates the count when a filter is changed
	 */
	initCount () {

		const searchForm = document.querySelector('.js-search-form')
		if (!searchForm) return

		const countElement = searchForm.querySelector('.js-count')
		if (!countElement) return

		const submitButton = countElement.closest('button')

		let countTimeout = null

		// cache current count
		if (countElement.getAttribute('data-count') !== '') {
			let url = window.location.href.split('#')[0]
			url = url.split('?')[0]
			url += '?count=1'
			url += '&' + new URLSearchParams(new FormData(searchForm)).toString()
			this.countCache[url] = parseInt(countElement.getAttribute('data-count'))
		}

		searchForm.addEventListener('change', () => {
			clearTimeout(countTimeout)
			this.updateCount(searchForm, countElement, submitButton)
		})

		searchForm.addEventListener('keyup', event => {

			clearTimeout(countTimeout)

			// if the key is enter, form will submit, so there's no need to update the count
			if (event.key === 'Enter') {
				return
			}

			if (event.target.tagName === 'INPUT') {
				this.updateCount(searchForm, countElement, submitButton, true)
				countTimeout = setTimeout(() => {
					this.updateCount(searchForm, countElement, submitButton)
				}, 1000)
			}
		})
	}

	/**
	 * Update the count
	 *
	 * @param {Object} searchForm
	 * @param {Object} countElement
	 * @param {Object|undefined} submitButton
	 * @param {Boolean} cacheOnly
	 * @param {Number} attempt
	 */
	updateCount (searchForm, countElement, submitButton, cacheOnly = false, attempt = 1) {

		// if we are already counting, wait until the current count is done
		if (this.isCounting) {
			setTimeout(() => {
				this.updateCount(searchForm, countElement, submitButton)
			}, 1000)
			return
		}

		// set flag to indicate we are counting
		this.isCounting = true

		// if there is a button, add loading class
		if (submitButton) {
			submitButton.classList.add('button--ajax-loading-alt')
		}

		// construct URL for fetch
		let url = window.location.href.split('#')[0]
		url = url.split('?')[0]
		url += '?count=1'
		url += '&' + new URLSearchParams(new FormData(searchForm)).toString()

		// if the count is in the cache, use it
		if (url in this.countCache) {
			countElement.textContent = '(' + this.countCache[url] + ')'
			countElement.setAttribute('data-count', this.countCache[url])
			if (submitButton) {
				submitButton.disabled = this.countCache[url] === 0
				submitButton.classList.remove('button--ajax-loading-alt')
			}
			this.isCounting = false
			return
		}

		// if we are only caching, don't fetch (real count will follow shortly)
		if (cacheOnly) {
			this.isCounting = false
			return
		}

		// get count from server
		fetch(url, {
			method: 'GET',
		})
			.then(response => response.json())
			.then(data => {
				this.countCache[url] = data.count
				countElement.textContent = '(' + data.count + ')'
				countElement.setAttribute('data-count', data.count)
				if (submitButton) {
					submitButton.classList.remove('button--ajax-loading-alt')
					submitButton.disabled = data.count === 0
				}
				this.isCounting = false
			})
			.catch(error => {
				console.error('Error:', error)
				this.isCounting = false
				if (submitButton) {
					submitButton.classList.remove('button--ajax-loading-alt')
					submitButton.disabled = data.count === 0
				}
			})
	}

	/**
	 * Initialize feature that keeps previous search in memory for back button
	 */
	initSearchHistory () {

		if (sessionStorage.getItem('previous-search-url')) {
			const searchLink = document.querySelector('.js-search-link')
			if (searchLink) {
				searchLink.setAttribute('href', sessionStorage.getItem('previous-search-url'))
			}
		}

		const searchResults = document.querySelector('.js-search-results')
		if (!searchResults) return

		sessionStorage.setItem('previous-search-url', window.location.pathname + window.location.search)

		const resetSearch = document.querySelector('.js-reset-search')
		if (resetSearch) {
			resetSearch.addEventListener('click', () => {
				sessionStorage.removeItem('previous-search-url')
			})
		}
	}

	/**
	 * Initialize feature that clears empty fields from the search form
	 */
	initClearEmptyFields () {
		const searchForm = document.querySelector('.js-search-form')
		if (!searchForm) return

		searchForm.addEventListener('submit', () => {
			const fields = searchForm.querySelectorAll('input, select')
			fields.forEach(field => {
				if (field.value === '') {
					field.disabled = true
				}
			})
		})
	}

}
