'use strict'

import Polyfills from './Polyfills.js'
import { Bartender } from '@fokke-/bartender.js'
import boringmenu from '@teppokoivula/boringmenu'
import Glightbox from 'glightbox'

/**
 * Site class contains general purpose site-specific features.
 *
 * @version 1.1.0
 */
export default class Site {

	/**
	 * Class constructor
	 *
	 * @param {Object} options Options for the class.
	 */
	constructor (options = {}) {
		this.options = {}

		// Init polyfills
		const polyfills = new Polyfills()
		polyfills.init()

		// Init Bartender after mobile menu is ready
		document.addEventListener('boringmenu-init-done', () => {
			window.bartender = new Bartender({
				el: '.bartender-main',
				contentEl: '.bartender-content',
				debug: false,
				trapFocus: true,
				overlay: true,
			})

			// window.bartender.addBar('bartender-right', {
			// 	el: '.bartender-right',
			// 	position: 'left',
			// 	mode: 'float',
			// })

			// document.querySelector('.bartender-open').addEventListener('click', (event) => {
			// 	window.bartender.toggle('bartender-right', event.target)
			// })

			// document.querySelector('.bartender-close').addEventListener('click', () => {
			// 	window.bartender.close()
			// })

			document.querySelectorAll('.bartender-bar.js-hide').forEach(bar => {
				bar.classList.remove('js-hide')
			})
		})

		// Init mobile menu
		const mobileMenu = document.getElementById('mobile-menu')
		new boringmenu({
			selectors: {
				menu: '.menu-mobile__list--level-1',
			},
			classes: {
				item: 'menu-mobile__item',
				itemActive: 'menu-mobile__item--current',
				itemParent: 'menu-mobile__item--parent',
				toggle: 'menu-mobile__toggle',
				toggleTextContainer: 'sr-only',
			},
			labels: {
				'menu.open': mobileMenu ? mobileMenu.getAttribute('data-labels-open') : 'open',
				'menu.close': mobileMenu ? mobileMenu.getAttribute('data-labels-close') : 'close',
			},
			icons: {
				'menu.open': 'icon-open',
				'menu.close': 'icon-close',
			},
		})

		// Initialize
		this.init(options)
	}

	/**
	 * Init the class by calling applicable init methods
	 *
	 * @param {Object} options Options for the class.
	 * @return {Object}
	 */
	init (options = {}) {

		// Merge user options to the defaults
		this.options = {
			responsiveTables: {
				selector: 'main table',
			},
			imageLinks: {
				parentSelector: 'main',
			},
			...options,
		}

		// Call individual init methods
		this.initResponsiveTables()
		this.initSkipLinks()
		this.initImageLinks()
		this.initAnchorLinks()
		this.initSmoothScrollLinks()
		this.initAutoFocus()
		this.initMinMaxInputs()

		// Dispatch custom event when init is done
		document.dispatchEvent(
			new CustomEvent('site-init-done', {
				bubbles: true,
				cancelable: true,
			})
		)

		return this
	}

	/**
	 * Initialize responsive tables
	 *
	 * Finds content tables and wraps them with div.table-wrapper.
	 */
	initResponsiveTables () {
		document.querySelectorAll(this.options.responsiveTables.selector).forEach(table => {
			if (!table.closest('.table-wrapper')) {
				const tableWrapper = document.createElement('div')
				tableWrapper.classList.add('table-wrapper')
				tableWrapper.classList.add('overflow-x-auto')
				table.parentNode.insertBefore(tableWrapper, table)
				tableWrapper.appendChild(table)
			}
		})
	}

	/**
	 * Initialize skip links
	 *
	 * Finds skip links and enhances their behaviour for various screen readers and mobile devices.
	 */
	initSkipLinks () {
		const skipLinks = document.querySelectorAll('.skip-link:not([data-skip-link])')
		if (skipLinks.length) {
			const skipToBlur = event => {
				if (event.target.getAttribute('data-tabindex')) {
					event.target.removeAttribute('tabindex')
					event.target.removeAttribute('data-tabindex')
				}
			}
			skipLinks.forEach(skipLink => {
				skipLink.setAttribute('data-skip-link', true)
				skipLink.addEventListener('click', event => {
					const skipTo = document.getElementById(event.target.href.split('#')[1])
					if (skipTo && skipTo.getAttribute('tabindex') != '-1') {
						event.preventDefault()
						skipTo.setAttribute('tabindex', '-1')
						skipTo.setAttribute('data-tabindex', true)
						skipTo.addEventListener('blur', skipToBlur)
						skipTo.addEventListener('focusout', skipToBlur)
						skipTo.focus()
					}
				})
			})
		}
	}

	/**
	 * Initialize image links
	 */
	initImageLinks () {

		// Parent of image links
		let parentNode = document.querySelector(this.options.imageLinks.parentSelector)
		if (!parentNode) return

		// Add glightbox class to image links
		parentNode.querySelectorAll('a[href$=".jpg"], a[href$=".jpeg"], a[href$=".png"], a[href$=".gif"]').forEach(link => {
			if (!link.classList.contains('glightbox')) {
				if (!link.getAttribute('data-title') && !link.getAttribute('data-glightbox')) {
					let figcaption = link.parentNode.querySelector('figcaption')
					if (figcaption) {
						let caption = figcaption ? figcaption.textContent : ''
						link.setAttribute('data-title', caption)
					}
				}
				link.classList.add('glightbox')
			}
		})

		// Initialize GLightbox
		if (parentNode.querySelector('.glightbox')) {
			window.glightbox = Glightbox()
		}
	}

	/**
	 * Initialize anchor links
	 *
	 * Add js-smooth-scroll class to anchor links to enable smooth scrolling and so that scroll position can be adjusted for fixed headers.
	 */
	initAnchorLinks () {

		const anchorLinks = document.querySelectorAll('a[href^="#"]:not([href="#"]):not(.js-smooth-scroll)')
		if (!anchorLinks.length) return

		anchorLinks.forEach(link => {
			link.classList.add('js-smooth-scroll')
		})
	}

	/**
	 * Initialize smooth scroll links
	 */
	initSmoothScrollLinks () {

		const smoothScroll = document.querySelectorAll('.js-smooth-scroll')
		if (!smoothScroll.length) return

		smoothScroll.forEach(el => {
			el.addEventListener('click', event => {
				event.preventDefault()
				const target = document.querySelector(el.getAttribute('href'))
				if (target) {
					if (el.getAttribute('href').indexOf('#') === 0) {
						history.pushState({}, '', el.getAttribute('href'))
					}
					this.smoothScrollTo(target)
				}
			})
		})
	}

	/**
	 * Smooth scroll to element
	 *
	 * @param {Element} target The target element to scroll to.
	 * @param {Boolean} focus Whether to focus on the target element after scrolling.
	 */
	smoothScrollTo (target, focus = true) {

		const contentArea = document.querySelector('.bartender-content') || null

		let adjustTop = 0
		// if (document.getElementById('adminbar')) {
		// 	adjustTop = document.getElementById('adminbar').offsetHeight
		// }

		contentArea.scrollTo({
			top: target.getBoundingClientRect().top + (contentArea === null ? window.scrollY : contentArea.scrollTop) - adjustTop,
			behavior: 'smooth',
		})

		if (focus) {
			target.focus({
				preventScroll: true,
			})
		}
	}

	/**
	 * Init auto-focus using smooth scroll
	 */
	initAutoFocus () {

		const focusElement = document.querySelector('.js-auto-focus')
		if (!focusElement) return

		this.smoothScrollTo(focusElement)
	}

	/**
	 * Initialize min/max input fields
	 */
	initMinMaxInputs() {
		const minMaxInputs = document.querySelectorAll('.js-min-max-input')
		if (minMaxInputs.length) {
			minMaxInputs.forEach(minMaxInput => {
				minMaxInput.addEventListener('blur', () => {
					const min = minMaxInput.getAttribute('data-min') || null
					const max = minMaxInput.getAttribute('data-max') || null
					const value = parseInt(minMaxInput.value)
					if (min !== null && value < min) {
						minMaxInput.value = min
						minMaxInput.dispatchEvent(new Event('input'), {bubbles: true})
					}
					if (max !== null && value > max) {
						minMaxInput.value = max
						minMaxInput.dispatchEvent(new Event('input'), {bubbles: true})
					}
				})
			})
		}
	}

}
